import { addListener } from '@helpers/listener';

const Selector = {
  SELECT_INPUT: 'app-select',
  SELECT_INPUT_VALUE: 'app-select__value',
};

const initSelectInputs = () => {
  addListener('change', `.${Selector.SELECT_INPUT} input`, (input) => {
    const label = input.nextElementSibling;
    const selectInput = input.closest(`.${Selector.SELECT_INPUT}`);
    const selectInputValue = selectInput.querySelector(
      `.${Selector.SELECT_INPUT_VALUE}`
    );

    selectInputValue.textContent = label.textContent;
  });
};

export { initSelectInputs };
