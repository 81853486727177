import { addListener } from '@helpers/listener';
import { blur, unblur } from '@helpers/misc';

const Selector = {
  MODAL: 'app-modal',
  JS_CLOSE_BTN: 'js-app-modal__close',
  BTN_SHOW_MODAL: '[data-modal-btn]',
  APP_CONTAINER: 'root',
  PORTAL: 'portal',
  PORTAL_ACTIVE: 'app-portal--active',
  PORTAL_WITH_EVENTS: 'app-portal--with-events',
};

const ModalSelector = {
  LOADING: 'loading',
  UNDERSTAND_USER: 'understand-user',
  GUIDE: 'guide',
  OBJECT_STORAGE_ACCESS_INFO: 'object-storage-access-info',
  OBJECT_STORAGE_PRICING: 'object-storage-pricing',
};

const modalsOrder = [
  ModalSelector.LOADING,
  ModalSelector.UNDERSTAND_USER,
  ModalSelector.GUIDE,
];

const Status = {
  IN_QUEUE: 'in-queue',
  COMPLETED: 'completed',
};

const getAppContainer = () =>
  document.querySelector(`#${Selector.APP_CONTAINER}`);
const getPortal = () => document.querySelector(`#${Selector.PORTAL}`);
const getModal = (selector) => {
  return document.body.querySelector(`[data-modal="${selector}"]`);
};
const hasBiggerPriority = (firstSelector, secondSelector) => {
  const firstIndex = modalsOrder.indexOf(firstSelector);
  const secondIndex = modalsOrder.indexOf(secondSelector);
  return firstIndex < secondIndex;
};
const getNextQueueItem = (modalsQueue) => {
  return Object.values(modalsQueue).reduce(
    (currentHighestPriorityItem, modalsQueueItem) => {
      if (modalsQueueItem.status === Status.COMPLETED) {
        return currentHighestPriorityItem;
      }

      if (currentHighestPriorityItem === null) {
        return modalsQueueItem;
      }

      return hasBiggerPriority(
        modalsQueueItem.selector,
        currentHighestPriorityItem.selector
      )
        ? modalsQueueItem
        : currentHighestPriorityItem;
    },
    null
  );
};

const setModalOverlay = (shouldShow, shouldBlur = false) => {
  const classListMethod = shouldShow ? 'add' : 'remove';
  const blurOrUnblur = shouldBlur ? blur : unblur;

  blurOrUnblur(getAppContainer());
  getPortal().classList[classListMethod](Selector.PORTAL_ACTIVE);
};

const updateModalsQueue = (queueItem) => {
  const modalsQueue = window.appData.modalsQueue.getValue();

  window.appData.modalsQueue.next({
    ...modalsQueue,
    [queueItem.selector]: {
      ...modalsQueue?.[queueItem.selector],
      ...queueItem,
    },
  });
};

const removeModalFromQueue = (selector) => {
  updateModalsQueue({ selector, status: Status.COMPLETED });
};

const hideModal = (selector) => {
  getModal(selector)?.removeAttribute('open');
  setModalOverlay(false);
};

const showModal = (modalSelector, onShow) => {
  const modal = getModal(modalSelector);
  modal.setAttribute('open', true);
  onShow && onShow();

  const shouldAvoidBlur = modal.hasAttribute('data-modal-should-avoid-blur');
  setModalOverlay(true, !shouldAvoidBlur);
};

const initShowModalButtons = () => {
  addListener('click', Selector.BTN_SHOW_MODAL, (btnShowModal) => {
    const modalSelector = btnShowModal.dataset.modalBtn;
    addModalToQueue(modalSelector);
  });
};

const initCloseButtons = () => {
  addListener('click', `.${Selector.JS_CLOSE_BTN}`, (closeBtn) => {
    const modal = closeBtn.closest('[data-modal]');
    removeModalFromQueue(modal.dataset.modal);
  });
};

const addModalToQueue = (selector, onShow) => {
  updateModalsQueue({
    selector,
    onShow,
    status: Status.IN_QUEUE,
  });
};

const addScrollListenerToPortal = () => {
  let timeout = null;

  getPortal().addEventListener('wheel', () => {
    clearTimeout(timeout);
    portal.classList.add(Selector.PORTAL_WITH_EVENTS);

    timeout = setTimeout(() => {
      portal.classList.remove(Selector.PORTAL_WITH_EVENTS);
    }, 100);
  });
};

const queueModals = () => {
  window.appData.modalsQueue.subscribe((modalsQueue) => {
    if (!modalsQueue) return;

    Object.keys(modalsQueue).forEach(hideModal);
    const activeQueueItem = getNextQueueItem(modalsQueue);

    if (!activeQueueItem) return;

    showModal(activeQueueItem.selector, activeQueueItem.onShow);
  });
};

const initModals = () => {
  queueModals();
  addScrollListenerToPortal();
  initCloseButtons();
  initShowModalButtons();
};

export {
  ModalSelector,
  initModals,
  addModalToQueue,
  removeModalFromQueue,
  getModal,
  getPortal,
};
