import { createSpinner } from '@components/atoms/spinner';
import { show, hide, disable, undisable } from '@helpers/misc';
import { addListener } from '@helpers/listener';

const Selector = {
  ICON: 'app-icon',
  JS_BTN_ICON_PROCESSING: 'js-app-btn__icon--processing',
  HELPER_HIDDEN: 'app-helper--hidden',
  SUBMIT_BTN: '[type="submit"]',
};

const setBtnProcessing = (btn) => {
  if (!btn) return;

  const icon = btn.querySelector(`.${Selector.ICON}`);
  icon && hide(icon);

  const spinner = createSpinner();
  spinner.classList.add(Selector.JS_BTN_ICON_PROCESSING);

  btn.insertBefore(spinner, btn.firstChild);
  disable(btn);
};

const unsetBtnProcessing = (btn) => {
  if (!btn) return;

  const spinnerIcon = btn.querySelector(`.${Selector.JS_BTN_ICON_PROCESSING}`);
  const icon = btn.querySelector(`.${Selector.ICON}.${Selector.HELPER_HIDDEN}`);

  spinnerIcon?.remove();
  icon && show(icon);
  undisable(btn);
};

const getSubmitBtn = (form) => {
  return (
    form.querySelector(Selector.SUBMIT_BTN) ||
    document.querySelector(`[form=${form?.id}]`)
  );
};

const makeButtonsFocusableOnSafari = () =>
  addListener('click', `button`, (button) => button.focus());

const initButtons = () => {
  makeButtonsFocusableOnSafari();
};

export { setBtnProcessing, unsetBtnProcessing, getSubmitBtn, initButtons };
