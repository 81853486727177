import { addDOMObserver } from '@helpers/listener';

const Selector = {
  MESSAGE: 'app-message',
  MESSAGE_LIST: 'app-message__list',
  MESSAGE_INTERIM: 'app-message--interim',
};

const getMessageList = () =>
  document.querySelector(`.${Selector.MESSAGE_LIST}`);
const getInterimMessages = () => [
  ...getMessageList().querySelectorAll(`.${Selector.MESSAGE_INTERIM}`),
];

const hideMessage = (message) => {
  setTimeout(() => {
    message.remove();
  }, 6000);
};

const removeMessages = () => {
  getMessageList().innerHTML = '';
};

const showMessage = ({ text, type, isInterim }, shouldAppend = false) => {
  const messageList = getMessageList();
  const insertionMethod = shouldAppend ? 'append' : 'replaceChildren';
  const message = document.createElement('p');

  message.classList.add(Selector.MESSAGE);
  message.classList.add(`${Selector.MESSAGE}--${type}`);
  isInterim && message.classList.add(Selector.MESSAGE_INTERIM);
  message.textContent = text;

  messageList[insertionMethod](message);
};

const initHidingMechanism = () => {
  getInterimMessages().forEach((message) => {
    if (message.classList.contains(Selector.MESSAGE_INTERIM)) {
      hideMessage(message);
    }
  });
  addDOMObserver(`.${Selector.MESSAGE_INTERIM}`, hideMessage, {
    wrapperSelector: `.${Selector.MESSAGE_LIST}`,
  });
};

const initMessages = () => {
  initHidingMechanism();
};

export { initMessages, showMessage, removeMessages };
