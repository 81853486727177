import configureForm from '@helpers/form';

const Selector = {
  JS_FORM: 'js-understand-user-form',
};

const InputName = {
  HOST_PURPOSE: 'host_purpose',
  EXPERIENCE: 'experience',
};

const addValidation = (component) => {
  configureForm(component, {
    rules: {
      [InputName.HOST_PURPOSE]: {
        required: true,
      },
      [InputName.EXPERIENCE]: {
        required: true,
      },
    },
    messages: {
      [InputName.HOST_PURPOSE]: {
        required: 'Please select the purpose',
      },
      [InputName.EXPERIENCE]: {
        required: 'Please select the experience',
      },
    },
  });
};

const initUnderstandUserForm = () => {
  const component = document.querySelector(`.${Selector.JS_FORM}`);
  addValidation(component);
};

export { initUnderstandUserForm };
