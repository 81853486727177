import Cookies from 'js-cookie';

const CookieKey = {
  HAS_SEEN_UNDERSTAND_USER_MODAL: 'has_seen_understand_user_modal',
};

const getCookie = (cookieKey) => {
  return JSON.parse(Cookies.get(cookieKey));
};

const setCookie = (cookieKey, cookieValue = true, options = undefined) => {
  Cookies.set(cookieKey, JSON.stringify(cookieValue), options);
};

const unsetCookie = (cookieKey) => {
  Cookies.remove(cookieKey);
};

export { CookieKey, getCookie, setCookie, unsetCookie };
