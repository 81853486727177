const Selector = {
  DROPDOWN: 'app-dropdown',
  DROPDOWN_ACTIVE: 'app-dropdown--active',
  DROPDOWN_MENU: 'app-dropdown__menu',
};

const getActiveDropdown = () => {
  return document.querySelector(`.${Selector.DROPDOWN_ACTIVE}`);
}

const hideCurrentlyActiveDropdownMenu = () => {
  const activeDropdown = getActiveDropdown();
  activeDropdown?.classList.remove(Selector.DROPDOWN_ACTIVE);
}

const initDropdowns = () => {
  document.addEventListener('click', (event) => {
    const dropdown = event.target.closest(`.${Selector.DROPDOWN}`);
    const isMenuClicked = !!event.target.closest(`.${Selector.DROPDOWN_MENU}`);
    const isAlreadyActive = dropdown === getActiveDropdown();

    if (!dropdown || isMenuClicked) {
      hideCurrentlyActiveDropdownMenu();
      return;
    }

    if (!isAlreadyActive) {
      hideCurrentlyActiveDropdownMenu();
    }

    dropdown?.classList.toggle(Selector.DROPDOWN_ACTIVE);
  });
}

export {
  initDropdowns,
}
