import { addListener } from '@helpers/listener';

const Selector = {
  JS_COUNTER_INPUT: 'js-app-counter-input',
  INPUT: 'input',
  JS_BTN_ADD: 'js-app-counter-input__btn--add',
  JS_BTN_SUB: 'js-app-counter-input__btn--sub',
};

const getInput = (counterInput) => {
  return counterInput.querySelector(`${Selector.INPUT}`);
};

const getMin = (counterInput) => {
  const input = getInput(counterInput);
  return Number(input.min) || 1;
};

const getMax = (counterInput) => {
  const input = getInput(counterInput);
  return Number(input.max) || Infinity;
};

const setValue = (counterInput, value) => {
  const input = getInput(counterInput);
  const min = getMin(counterInput);
  const max = getMax(counterInput);

  if (value === '') {
    input.value = value;
    input.dispatchEvent(new Event('change', { bubbles: true }));
    return;
  }

  value = Number(value) || min;

  if (value > max) {
    value = max;
  }

  if (value < min) {
    value = min;
  }

  input.value = value;
  input.dispatchEvent(new Event('change', { bubbles: true }));
};

const getValue = (counterInput) => {
  return Number(getInput(counterInput).value);
};

const increment = (counterInput) => {
  setValue(counterInput, getValue(counterInput) + 1);
};

const decrement = (counterInput) => {
  setValue(counterInput, getValue(counterInput) - 1);
};

const addBtnAddListener = () => {
  addListener('click', `.${Selector.JS_BTN_ADD}`, (btnAdd) => {
    const counterInput = btnAdd.closest(`.${Selector.JS_COUNTER_INPUT}`);
    increment(counterInput);
  });
};

const addBtnSubListener = () => {
  addListener('click', `.${Selector.JS_BTN_SUB}`, (btnSub) => {
    const counterInput = btnSub.closest(`.${Selector.JS_COUNTER_INPUT}`);
    decrement(counterInput);
  });
};

const addOnInputListener = () => {
  addListener(
    'input',
    `.${Selector.JS_COUNTER_INPUT} ${Selector.INPUT}`,
    (input) => {
      const counterInput = input.closest(`.${Selector.JS_COUNTER_INPUT}`);
      setValue(counterInput, input.value);
    }
  );
};

const initCounterInputs = () => {
  addBtnAddListener();
  addBtnSubListener();
  addOnInputListener();
};

export { initCounterInputs };
