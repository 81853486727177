export const addListener = (
  eventType,
  targetSelector,
  callback,
  { wrapperSelector } = { wrapperSelector: 'body' }
) => {
  const wrapper = document.querySelector(wrapperSelector);

  wrapper.addEventListener(eventType, (event) => {
    runCallbackIfTargetExists(event, targetSelector, callback);
  });
};

export const addDOMObserver = (
  targetSelector,
  callback,
  { wrapperSelector, mutationObserverOptions } = {
    wrapperSelector: 'body',
  }
) => {
  mutationObserverOptions = mutationObserverOptions || {
    attributes: true,
    childList: true,
    subtree: true,
  };
  const wrapper = document.querySelector(wrapperSelector);

  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      runCallbackIfTargetExists(mutation, targetSelector, callback);
      [...mutation.addedNodes].forEach((node) => {
        const target = node.closest(targetSelector);
        target && callback(target, mutation);
      });
    });
  });

  observer.observe(wrapper, mutationObserverOptions);
};

const runCallbackIfTargetExists = (
  eventOrMutation,
  targetSelector,
  callback
) => {
  const target = eventOrMutation.target.closest(targetSelector);
  target && callback(target, eventOrMutation);
};
