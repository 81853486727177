import { CookieKey, setCookie } from '@helpers/cookies';
import { initUnderstandUserForm } from '@views/_global/modals/understand-user-form';
import { ModalSelector, addModalToQueue } from '@components/molecules/modal';

const initGlobalModals = () => {
  if (window.appData.shouldSeeUnderstandUserModal) {
    initUnderstandUserForm();
    addModalToQueue(ModalSelector.UNDERSTAND_USER, () => {
      setCookie(CookieKey.HAS_SEEN_UNDERSTAND_USER_MODAL);
    });
  }
};

export { initGlobalModals };
