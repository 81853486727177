const Selector = {
  TOOLTIP: 'app-tooltip',
  TOOLTIP_ACTIVE: 'app-tooltip--active'
};

const addOnClick = () => {
  document.addEventListener('click', (event) => {
    const tooltip = event.target.closest(`.${Selector.TOOLTIP}`);
    tooltip?.classList.toggle(Selector.TOOLTIP_ACTIVE);
  });
}

const addOnMouseOver = () => {
  document.addEventListener('mouseover', (event) => {
    const tooltip = event.target.closest(`.${Selector.TOOLTIP}`);
    tooltip?.classList.add(Selector.TOOLTIP_ACTIVE);
  });
}

const addOnMouseOut = () => {
  document.addEventListener('mouseout', (event) => {
    const tooltip = event.target.closest(`.${Selector.TOOLTIP}`);
    tooltip?.classList.remove(Selector.TOOLTIP_ACTIVE);
  });
}

const initTooltips = () => {
  addOnClick();
  addOnMouseOver();
  addOnMouseOut();
}

export {
  initTooltips,
}
