import { BehaviorSubject } from 'rxjs';

const appData = {
  assets: {},
  user: {},
  modalsQueue: new BehaviorSubject(),
  edgeInstances: {
    new: {},
  },
  edgeSpaces: {
    index: {},
  },
};

export { appData };
