const Selector = {
  JS_FILE_INPUT: 'js-app-file-input',
  INPUT: 'input[type=file]',
  UPLOADED: 'app-file-input--uploaded'
};

const getInnerInput = (fileInput) => {
  return fileInput.querySelector(Selector.INPUT);
}

const addOnClickForwarding = () => {
  document.addEventListener('click', () => {
    const fileInput = event.target.closest(`.${Selector.JS_FILE_INPUT}`);

    if (!fileInput) {
      return;
    }

    getInnerInput(fileInput).click();
  });
}

const addOnFileUpload = () => {
  document.addEventListener('change', (event) => {
    const fileInput = event.target.closest(`.${Selector.JS_FILE_INPUT}`);

    if (!fileInput) {
      return;
    }

    const fileName = getInnerInput(fileInput).files[0]?.name || '';

    if (fileName !== '') {
      fileInput.dataset.fileName = fileName;
      fileInput.classList.add(Selector.UPLOADED);
    }
  });
}

const initFileInputs = () => {
  addOnClickForwarding();
  addOnFileUpload();
}

export {
  initFileInputs,
}
