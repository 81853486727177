import {
  setBtnProcessing,
  unsetBtnProcessing,
  getSubmitBtn,
} from '@components/atoms/btn';

const Selector = {
  FORM: 'form',
  INPUT_WRAPPER: 'app-input',
  INPUT_ITEM: 'app-input__item',
  INPUT_ITEM_SUCCESS: 'app-input__item--success',
  INPUT_ITEM_ERROR: 'app-input__item--error',
  ERROR: 'app-input__error',
  SUBMIT_BTN: '[type=submit]',
};

const configureForm = (element, { submitHandler, ...restOptions }) => {
  $(element).validate({
    submitHandler: (form) => {
      const submitBtn = getSubmitBtn(form);
      setBtnProcessing(submitBtn);

      submitHandler ? submitHandler(form) : form.submit();
    },
    errorElement: 'p',
    errorClass: Selector.ERROR,
    onkeyup: (elem) => {
      const isValid = $(elem).valid();
      const $input = $(elem).closest(`.${Selector.INPUT_ITEM}`);

      if (isValid) {
        $input.removeClass(Selector.INPUT_ITEM_ERROR);
        $input.addClass(Selector.INPUT_ITEM_SUCCESS);
      } else {
        $input.removeClass(Selector.INPUT_ITEM_SUCCESS);
        $input.addClass(Selector.INPUT_ITEM_ERROR);
      }

      return isValid;
    },
    errorPlacement: (error, element) => {
      const form = element.get(0).closest(Selector.FORM);
      const submitBtn = getSubmitBtn(form);
      const $inputWrapper = $(element).closest(`.${Selector.INPUT_WRAPPER}`);
      const $input = $inputWrapper.find(`.${Selector.INPUT_ITEM}`);
      const $prevErrors = $inputWrapper.find(`.${Selector.ERROR}`);
      console.log('@@@ $inputWrapper | ', $inputWrapper);
      unsetBtnProcessing(submitBtn);
      $input.removeClass(Selector.INPUT_ITEM_SUCCESS);
      $input.addClass(Selector.INPUT_ITEM_ERROR);
      $prevErrors?.remove();
      $inputWrapper.append(error);
    },
    ...restOptions,
  });
};

export default configureForm;
