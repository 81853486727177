import { show } from '@helpers/misc';

const Selector = {
  WRAPPER: 'app-input',
  ITEM: 'app-input__item',
  ITEM__ERROR: 'app-input__item--error',
  ERROR: 'app-input__error',
};

// config/initializers/inline_errors.rb
// Not able to get input parent to be able to insert error in exact place
const moveServerSideErrors = () => {
  const errors = [...document.querySelectorAll(`.${Selector.ERROR}`)];
  errors.forEach((error) => {
    const wrapper = error.closest(`.${Selector.WRAPPER}`);
    const inputItem = wrapper.querySelector(`.${Selector.ITEM}`);
    inputItem.classList.add(Selector.ITEM__ERROR);

    wrapper.append(error);
    show(error);
  });
};

const initInputs = () => {
  moveServerSideErrors();
};

export { initInputs };
