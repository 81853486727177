import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { initInputs } from '@components/atoms/inputs/input';
import { initTextInputs } from '@components/atoms/inputs/text-input';
import { initSelectInputs } from '@components/atoms/inputs/select';
import { initModals } from '@components/molecules/modal';
import { initDropdowns } from '@components/atoms/dropdown';
import { initFileInputs } from '@components/atoms/inputs/file-input';
import { initTooltips } from '@components/atoms/tooltip';
import { initCounterInputs } from '@components/molecules/counter-input';
import { initTabs } from '@components/molecules/tabs';
import { initHeader } from '@components/organisms/header';
import { initNavbar } from '@components/organisms/navbar';
import { initMessages } from '@components/atoms/message';
import { initButtons } from '@components/atoms/btn';
import { appData } from '@views/_global/app-data';
import { initGlobalModals } from '@views/_global/modals';

// Init global object to communicate between rails templates and js modules
window.appData = appData;

window.addEventListener('load', () => {
  initInputs();
  initMessages();
  initSelectInputs();
  initTextInputs();
  initModals();
  initButtons();
  initHeader();
  initNavbar();
  initFileInputs();
  initTooltips();
  initDropdowns();
  initCounterInputs();
  initTabs();
  initGlobalModals();
});
