import { addListener } from '@helpers/listener';

const Selector = {
  TEXT_INPUT: 'app-text-input',
  TOGGLE_PASSWORD: 'app-text-input__toggle-password',
};

const initPasswordInputs = () => {
  addListener(
    'change',
    `.${Selector.TOGGLE_PASSWORD}`,
    (togglePasswordCheckbox) => {
      const textInput = togglePasswordCheckbox.closest(
        `.${Selector.TEXT_INPUT}`
      );
      const input = textInput.querySelector('input');
      input.type = input.type === 'password' ? 'text' : 'password';
    }
  );
};

const initTextInputs = () => {
  initPasswordInputs();
};

export { initTextInputs };
