const Selector = {
  LOGO: 'app-navbar__logo',
};

const getLogo = () => document.body.querySelector(`.${Selector.LOGO}`);

const setLogo = () => {
  const isMobile = window.innerWidth < 576;
  const logo = getLogo();
  if (logo) {
    logo.textContent = isMobile ? 'C+' : 'CLOUD COMPUTE';
  }
};

const addResizeListener = () => {
  setLogo();
  window.addEventListener('resize', setLogo);
};

const initNavbar = () => {
  addResizeListener();
};

export { initNavbar };
