/**
 * Set only one from multiple containers (tabs)
 * tab.dataset.tab - tab indicator
 * radio.dataset.tabToShow - which tab to show on radio select
 */

import { addListener } from '@helpers/listener';
import { hide, show } from '@helpers/misc';

const Selector = {
  JS_TABS: 'js-app-tabs',
  JS_RADIO: 'js-app-tabs__radio',
  JS_TAB: 'js-app-tabs__item',
  CHECKED_RADIO: 'input:checked',
};

const getTabs = (wrapper) => {
  return [...wrapper.querySelectorAll(`.${Selector.JS_TAB}`)];
};

const setActiveTab = (wrapper, tabToShow) => {
  getTabs(wrapper).forEach((tab) => {
    tab.dataset.tab === tabToShow ? show(tab) : hide(tab);
  });
};

const setActiveTabOnPageLoad = (wrapper) => {
  const selectedRadio = wrapper.querySelector(
    `.${Selector.JS_RADIO} ${Selector.CHECKED_RADIO}`
  );

  if (!selectedRadio) {
    return;
  }

  const selectedRadioWrapper = selectedRadio.closest(`.${Selector.JS_RADIO}`);
  setActiveTab(wrapper, selectedRadioWrapper.dataset.tabToShow);
};

const addOnTabSelectListener = () => {
  addListener('change', `.${Selector.JS_RADIO}`, (clickedRadioWrapper) => {
    const tabsComponent = clickedRadioWrapper.closest(`.${Selector.JS_TABS}`);
    setActiveTab(tabsComponent, clickedRadioWrapper.dataset.tabToShow);
  });
};

const initTabsOnPageLoad = () => {
  const tabsComponents = [...document.querySelectorAll(`.${Selector.JS_TABS}`)];
  tabsComponents.forEach((tabsComponent) =>
    setActiveTabOnPageLoad(tabsComponent)
  );
};

const initTabs = () => {
  addOnTabSelectListener();
  initTabsOnPageLoad();
};

export { initTabs };
