const Selector = {
  TEMPLATE_SPINNER: "template-spinner",
  ICON: "app-icon",
};

const createSpinner = () => {
  const spinnerTemplate = document.querySelector(
    `.${Selector.TEMPLATE_SPINNER}`
  );
  const spinner = spinnerTemplate.content
    .cloneNode(true)
    .querySelector(`.${Selector.ICON}`);
  return spinner;
};

const replaceContentWithSpinner = (element) => {
  const spinner = createSpinner();
  element.innerHTML = "";
  element.append(spinner);
};

export { createSpinner, replaceContentWithSpinner };
