import { addListener } from '@helpers/listener';

const Selector = {
  LOGOUT_BTN: 'js-app-profile-btn__logout',
};

const addOnLogoutListener = () => {
  // TOTO: Apply to confirmation button, not the dropdown item
  addListener('click', `.${Selector.LOGOUT_BTN}`, () => {
    sessionStorage.clear();
  });
};

const initHeader = () => {
  addOnLogoutListener();
};

export { initHeader };
