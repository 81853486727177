const Selector = {
  HELPER_HIDDEN: 'app-helper--hidden',
  BLURRED: 'app-helper--blurred',
};

const Attribute = {
  DISABLED: 'disabled',
};

const getParents = (parentSelector, element) => {
  let parent = element.parentNode.closest(parentSelector);

  if (!parent) return [];

  return [parent, ...getParents(parentSelector, parent)];
};

const show = (element) => element.classList.remove(Selector.HELPER_HIDDEN);
const hide = (element) => element.classList.add(Selector.HELPER_HIDDEN);
const disable = (element) => element.setAttribute(Attribute.DISABLED, true);
const undisable = (element) => element.removeAttribute(Attribute.DISABLED);
const blur = (element) => element.classList.add(Selector.BLURRED);
const unblur = (element) => element.classList.remove(Selector.BLURRED);

export { getParents, show, hide, disable, undisable, blur, unblur };
